import React from 'react'
import cx from 'classnames'
import { graphql, PageProps } from 'gatsby'
import { useMediaQuery } from 'react-responsive'
import useReportsPage from '../../utils/use-reports-page'
import { Link } from '../../components/link'
import Layout from '../../layouts/index'
import { SEO } from '../../components/seo'
import { Icon } from '../../components/icon'
// import { board } from '../../components/board/board';

import {
  ChatBubbleOvalLeftEllipsisIcon
} from '@heroicons/react/24/outline'

export default function QuarterlyReports(props: PageProps) {
  const quarterlyResults = props.data.quarterlyResult.group
  const reportsPage = useReportsPage()

  quarterlyResults.sort(function (a, b) {
    return b.fieldValue - a.fieldValue
  })

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)'
  })

  return (
    <Layout
      location={props.location}
      heros={reportsPage.hero}
      herosFallbackTitle={reportsPage.title}
    >
      <div className="container flex flex-col mx-auto mt-8 mb-20 lg:mt-4">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            {quarterlyResults.length > 0 ? (
              <>
                {isMobile ? (
                  <div className="overflow-hidden border-gray-200 shadow dark:border-gray-800 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                    {/* Mobile Table Body */}
                    <tbody className="bg-white dark:bg-gray-900">
                      {quarterlyResults.map((yearlyResult) => (
                        <>
                          {/* Year Results Wrapper */}
                          {yearlyResult.edges.map((result, index) => (
                            <div
                              key={result.node.date}
                              className={cx('border-b dark:bg-gray-900 dark:border-gray-700 whitespace-nowrap', {
                                'dark:divide-gray-700':
                                  index === 0
                              })}
                            >
                              {/* Table Row 1 */}
                              {index === 0 && (
                                <tr className={cx('dark:bg-gray-900 dark:border-gray-700 whitespace-nowrap', {
                                  'dark:divide-gray-700':
                                  index === 0
                                })}>
                                  {/* Year Result Data 1 */}
                                  <td
                                  className="px-6 py-4 text-center dark:border-gray-700 whitespace-nowrap"
                                >
                                  <div className="mt-2 -mb-2 text-lg font-medium text-gray-400 dark:text-gray-600 ">
                                    {yearlyResult.fieldValue}
                                  </div>
                                  </td>
                                  {/* Year Result Padding 2 */}
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="text-sm text-gray-900 dark:text-gray-200">
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {/* Table Row 2 */}
                                <tr>
                                {/* Quarter Result Body 1 */}
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="flex-auto w-12 ml-3 text-sm text-gray-900 dark:text-gray-200">
                                    {result.node.quarter}
                                  </div>
                                </td>
                                {/* Document Type Link List Body 2 */}
                                <td className="px-6 pb-6 pt-10 whitespace-nowrap">
                                  <div className="text-sm mb-4 text-gray-900 dark:text-gray-200">
                                    {result.node.reportRef.map((report, index) => (
                                      <>
                                        {report.documentFile && (
                                          <Link
                                            className="flex mb-5 items-center font-medium hover:underline"
                                            to={report.documentFile.file.url}
                                          >
                                            <Icon
                                              documentIcon={report.documentIcon}  
                                              className="w-6 h-6 mr-1 opacity-50"
                                              aria-hidden="true"
                                            />{' '}
                                              {report.documentText}
                                          </Link>
                                        )}
                                        {report.documentUrl && (
                                          <Link
                                            to={report.documentUrl}
                                            className="flex mb-5 items-center font-medium hover:underline"
                                          >
                                            <Icon
                                              documentIcon={report.documentIcon}  
                                              className="w-6 h-6 mr-1 opacity-50"
                                              aria-hidden="true"
                                            />{' '}
                                            {report.documentText}
                                          </Link>
                                        )}
                                      </>
                                    ))}

                                  </div>
                                </td>
                              </tr>
                            </div>
                          ))}
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
                ) : (
                  <div className="mt-12 overflow-hidden border-b border-gray-200 shadow dark:border-gray-800 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                      {/* Desktop Table Header */}
                      <thead className="bg-gray-100 dark:bg-gray-800">
                        <tr>
                          {/* Year Header 1 */}
                          <th
                            scope="col"
                            className="w-70 px-10 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                            Year
                          </th>
                          {/* Quarter Period Header 3 */}
                          <th
                            scope="col"
                            className="w-45 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                            Period
                          </th>
                          {/* Document Title Header 5 */}
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                            Title
                          </th>
                          {/* Padding Header 4 */}
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                          </th>
                          
                          {/* Padding Header 6 */}
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                          </th>
                          {/* Padding Header 7 */}
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                          </th>
                          {/* Padding Header 8 */}
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                          </th>
                        </tr>
                      </thead>
                      {/* Desktop Table Body */}
                      <tbody className="bg-white dark:bg-gray-900">
                        {quarterlyResults.map((yearlyResult) => (
                          <>
                          {/* Table Row 1 */}
                            {yearlyResult.edges.map((result, index) => (
                              <tr
                                key={result.node.date}
                                className={cx('dark:bg-gray-900 border-t dark:border-gray-700 whitespace-nowrap', {
                                  'divide-y divide-gray-200 dark:divide-gray-700':
                                    index === 0
                                })}
                              >
                                {/* Year Result Body 1 */}
                                {index === 0 && (
                                  <td
                                    rowSpan={yearlyResult.edges.length}
                                    className="w-70 px-10 py-5 text-left dark:border-gray-700 whitespace-nowrap">
                                      <div className="relative left-0 top-0 text-lg font-medium text-gray-400 dark:text-gray-600 ">
                                        {yearlyResult.fieldValue}
                                      </div>
                                  </td>
                                )}
                                {/* Quarter Result Body 3 */}
                                <td className="w-45 px-6 py-5 whitespace-nowrap">
                                  <div className="text-sm text-gray-900 dark:text-gray-200">
                                    {result.node.quarter}
                                  </div>
                                </td>
                                {/* Document Type Link List Body 4 */}
                                <td className="px-6 py-1 whitespace-nowrap">
                                  <div className="text-sm text-gray-900 dark:text-gray-200">

                                    {result.node.reportRef.map((report, index) => (
                                      <>
                                        {report.documentFile && (
                                          <Link
                                            className="my-4 flex items-center font-medium hover:underline"
                                            to={report.documentFile.file.url}
                                          >
                                            <Icon
                                              documentIcon={report.documentIcon}  
                                              className="w-6 h-6 mr-1 opacity-50"
                                              aria-hidden="true"
                                            />{' '}
                                              {report.documentText}
                                          </Link>
                                        )}
                                        {report.documentUrl && (
                                          <Link
                                            to={report.documentUrl}
                                            className="my-4 flex items-center font-medium hover:underline"
                                          >
                                            <Icon
                                              documentIcon={report.documentIcon}  
                                              className="w-6 h-6 mr-1 opacity-50"
                                              aria-hidden="true"
                                            />{' '}
                                            {report.documentText}
                                          </Link>
                                        )}
                                      </>
                                    ))}


                                  </div>
                                </td>

                               
                                {/* Padding Body 5 */}
                                <td className="px-6 py-2 whitespace-nowrap">
                                  <div className="text-sm text-gray-900 dark:text-gray-200">
                                  </div>
                                </td> 
                                {/* Padding Body 6 */}
                                <td className="px-6 py-2 whitespace-nowrap">
                                  <div className="text-sm text-gray-900 dark:text-gray-200">
                                  </div>
                                </td> 
                                {/* Padding Body 7 */}
                                <td className="px-6 py-2 whitespace-nowrap">
                                  <div className="text-sm text-gray-900 dark:text-gray-200">
                                  </div>
                                </td> 
                                {/* Padding Body 8 */}
                                <td className="px-6 py-2 whitespace-nowrap">
                                  <div className="text-sm text-gray-900 dark:text-gray-200">
                                  </div>
                                </td> 
                              </tr>
                            ))}
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </>
            ) : (
              <>
                {reportsPage.noResults ? (
                  <div className=" items-center flex justify-center flex-col py-20 rounded bg-gray-100 dark:bg-gray-900 mx-auto text-lg text-black dark:text-white">
                    <ChatBubbleOvalLeftEllipsisIcon className="w-12 h-12 text-gray-500" />
                    <h6 className="flex justify-center mx-auto text-md text-gray-500">
                      {reportsPage.noResults.noResults}
                    </h6>
                  </div>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => (
  <SEO />
)

export const pageQuery = graphql`
  query {
    quarterlyResult: allContentfulQuarterlyResult(
      sort: {fields: [sticky, earningsDate], order: [DESC, DESC]}
      filter: {shown: {eq: true}}
    ) {
      group(field: year) {
        fieldValue
        edges {
          node {
            contentful_id
            year
            quarter
            date: earningsDate
            webcast
            filingsText
            reportRef {
              documentUrl
              documentText
              documentIcon
              documentFile {
                file {
                  url
                }
              }
            }
          }
        }
      }
    }
  }

`